import React, { Component } from "react";
import { NavLink, Route, Routes, } from 'react-router-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BASE_URL } from "./axios/config";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardText
} from 'mdb-react-ui-kit';
import "./App.css";
import Footer from './pages/Footer/Footer';
import routes from "./router/router";
import { Rotate, Slide } from "react-reveal";
import Zoom from 'react-reveal/Zoom';
import SpeedDial from '@mui/material/SpeedDial';
import Drawer from '@mui/material/Drawer';
import webLogo from './assets/logo.png';
import { get_meta_info } from "./axios/Api";
import { Helmet } from "react-helmet";
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_hide: false,
      hide_btn: 'block',
      logo_url: '',
      logo_link: '',
      show_top: 'none',
      show_qr: false,
      wx_qr: 'img/wx.png',
      sqr_qr: 'img/sqr.png',
      desc:'',
      keys:'',
      site_name:''
    }
    this.handleResize = this.handleResize.bind(this);
  }
  //挂载
  componentDidMount() {
    const getStyle = window.getComputedStyle;
    window.getComputedStyle = (element, property) => {
      if (!element || element.nodeType === 9) return {};
      return getStyle(element, property);
    }
    //添加滚动监听
    window.addEventListener('scroll', this.bindHandleScroll);
    window.addEventListener('resize', this.handleResize);
    this.getMetaInfo();
  }
  getMetaInfo = async() => {
    get_meta_info().then(res => {
        if(res.data.code === 200){
          document.title = res.data.data[0].site_name;
          this.setState({
            desc:res.data.data[0].site_des,
            keys:res.data.data[0].site_key,
            site_name:res.data.data[0].site_name
          })
          // let m = document.getElementsByTagName("meta");
          // for(let i = 0; i < m.length; i++){
          //   if(m[i].name === 'keywords'){
          //     m[i].setAttribute('content',res.data.data[0].site_key);
          //   }
          // }
        }
    }).catch(error => {

    })
  }
  getLogoInfo = (u) => {
    if (u[0] !== "") {
      this.setState({
        logo_url: BASE_URL + u[0],
        logo_link: u[1]
      });
    }
    if (u[2] !== "") {
      this.setState({
        wx_qr: BASE_URL + u[2]
      });
    }
    if (u[3] !== "") {
      this.setState({
        sqr_qr: BASE_URL + u[3]
      });
    }
  }
  //卸载
  componentWillUnmount() {
    window.addEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.bindHandleScroll);
  }
  //监听屏幕变化
  handleResize() {
    if (
      //判断移动端隐藏登录及注册按钮
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.setState({
        // hide_btn: 'none',
        show_hide:false
      })
    } else {
      // this.setState({
      //   hide_btn: 'block',
      // })
    }
  }
  //屏幕滚动指定位置出现返回顶部按钮
  bindHandleScroll = (e) => {
    const scrollTop = (e.srcElement ? e.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (e.srcElement ? e.srcElement.body.scrollTop : 0);
    if (scrollTop > 400) {
      this.setState({
        show_top: 'block'
      });
    } else {
      this.setState({
        show_top: 'none'
      });
    }
  }
  //滚动到顶部
  btn_scrollTop() {
    document.body.scrollIntoView();
  }
  //展示二维码
  show_qrcode() {
    if (this.state.show_qr === true) {
      this.setState({
        show_qr: false
      })
    } else {

      this.setState({
        show_qr: true
      })
    }
  }
  //显示或者隐藏菜单栏
  show_hide() {
    if (this.state.show_hide === true) {
      this.setState({
        show_hide: false,
      })
    } else {
      this.setState({
        show_hide: true,
      })
    }
  };
  render() {
    return (
      <>
      <div>
      <Helmet>
          <title>{this.state.site_name}</title>
          <meta name="keywords" content={this.state.keys} />
          <meta name="description" content={this.state.desc} />
      </Helmet>
      </div>
        <MDBContainer fluid style={{ padding: '0' }}>
          <MDBNavbar className="diy-navbar" sticky expand='lg' dark bgColor='dark'>
            <MDBContainer>
              <MDBNavbarBrand className="diy-navbar-brand">
                <Zoom left>
                  <img className="diy-brand-img"
                    src={this.state.logo_url===''?webLogo:this.state.logo_url}
                    height='30'
                    alt=''
                    loading='lazy'
                  />
                </Zoom>
              </MDBNavbarBrand>
              <MDBNavbarToggler
                type='button'
                data-target='#qpai'
                aria-controls='qpai'
                aria-expanded='false'
                aria-label='Toggle navigation'
                onClick={this.show_hide.bind(this)}
              >
                <MDBIcon icon='bars' fas />
              </MDBNavbarToggler>
              <MDBCollapse navbar show={this.state.show_hide} id="qpai">
                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0' style={{ justifyContent: 'center',minWidth:'1100px' }}>
                  {
                    routes.map((item, index) => {
                      return (
                        (
                          <MDBNavbarItem key={index} style={{display:item.display}} onClick={this.show_hide.bind(this)}>
                            <Rotate bottom right>
                              <NavLink className='nav-link' to={item.path}>{item.label}</NavLink>
                            </Rotate>
                          </MDBNavbarItem>
                        )
                      )
                    })
                  }
                  <Slide right className="app-reg-login">
                    <MDBBtn tag='a' href={this.state.logo_link} target="_blank" rounded className='mx-2 app-reg-login'>登录&nbsp;&nbsp;/&nbsp;&nbsp;注册</MDBBtn>
                  </Slide>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBContainer>
          </MDBNavbar>
          <Routes>
            {
              routes.map((item, index) => {
                return (
                  (
                    <Route
                      key={index}
                      path={item.path}
                      element={item.element}
                    />
                  )
                )
              })
            }
          </Routes>
          {/* 滚动到顶部 */}
          <MDBBtn onClick={this.btn_scrollTop} floating size='lg' tag='a' style={{ display: this.state.show_top, position: 'fixed', zIndex: '1000', right: '2%', bottom: '15%', backgroundColor: '#9FA6B2' }}>
            <Slide bottom>
              <MDBIcon fas icon="angle-double-up" />
            </Slide>
          </MDBBtn>
          <Footer getlogo={this.getLogoInfo}></Footer>
        </MDBContainer>
          <SpeedDial
            ariaLabel="更多操作"
            sx={{ position: 'fixed', bottom: '40%', right: '1%' }}
            onClick={this.show_qrcode.bind(this)}
            icon={<MDBIcon fas icon="comment-dots" style={{ fontSize: '1.9rem', color: '#03a9f4'}} />}
          >
          </SpeedDial>
          <Drawer
            anchor="left"
            open={this.state.show_qr}
            onClose={this.show_qrcode.bind(this)}
          >
            <MDBRow className="drawer-row">
              <MDBCol xl={12}>
                <MDBCard>
                  <MDBCardImage className="drawer-qr-img" src={this.state.wx_qr} alt='公众号二维码' position='top' />
                  <MDBCardBody className="drawer-qr-text">
                    <MDBCardText>
                      公众号二维码
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol xl={12} className="mt-3">
                <MDBCard>
                  <MDBCardImage className="drawer-qr-img" src={this.state.sqr_qr} alt='客服二维码' position='top' />
                  <MDBCardBody className="drawer-qr-text">
                    <MDBCardText>
                      客服二维码
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </Drawer>
      </>
    );
  }
}


