import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import "mdb-react-ui-kit/dist/css/mdb.min.css";

ReactDOM.createRoot(document.getElementById('root')).render(

  <Router>
    <App />
  </Router>
);
