import React, { Component } from "react"; import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCardOverlay
} from 'mdb-react-ui-kit';
import { Slide, Fade } from 'react-reveal';
import QueueAnim from 'rc-queue-anim';
import ScrollAnim from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import 'rc-texty/assets/index.css';
import '../video/video.css';
import videoBanner from '../../assets/banner2.png';
import {
    get_video_banner,
    get_video_sub1,
    get_video_sub2,
    get_video_sub4,
    get_video_base,
    get_video_sub3
} from "../../axios/Api";
import { BASE_URL } from "../../axios/config";
const ScrollOverPack = ScrollAnim.OverPack;
export default class Video extends Component {
    constructor(props) {
        super(props);
        this.state = {
            video_banner: '',
            sub1: [],
            sub1_head: '',
            sub2:[],
            sub2_head:'',
            sub4: [],
            sub4_head: '',
            base: [],
            sub3: [],
            sub3_head: ''
        }
    }
    componentDidMount() {
        document.body.scrollIntoView();
        this.getBanner();
        this.getBase();
        this.getSub1();
        this.getSub2();
        this.getSub4();
        this.getSub3();

    }
    //获取横幅
    getBanner = async () => {
        get_video_banner().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    video_banner: BASE_URL+(res.data.data.image_src.length > 0? res.data.data.image_src:videoBanner)
                })
            }
        }).catch(error => {

        })
    }
    //基本信息
    getBase = async () => {
        get_video_base().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    base: res.data.data
                })
            }
        }).catch(error => {

        })
    }
    //渲染图片
    initPhoto() {
        if (this.state.base !== []) {
            return (
                <MDBCardImage
                    src={BASE_URL + this.state.base.photo}
                    alt=''
                    position='top'
                    className="video-map-card-img"
                />
            )
        }
    }
    //渲染底部
    initFooter() {
        if (this.state.base !== []) {
            return (
                <>
                    <MDBCol size='12' md='12' className="mb-3">
                        <Fade bottom>
                            <div className="video-flow-col-title">
                                <h1>
                                    <span>{this.state.base.footer_desc}</span>
                                </h1>
                            </div>
                        </Fade>
                    </MDBCol>
                    <Fade bottom>
                        <MDBCol size='12' md='12' className="mb-3 video-use-now">
                            <MDBBtn className='mx-2' rounded size='lg' tag='a' target="_blank" href={this.state.base.button_link}>{this.state.base.button_name}</MDBBtn>
                        </MDBCol>
                    </Fade>
                </>
            )
        }
    }
    //第一段
    getSub1 = async () => {
        get_video_sub1().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    sub1: res.data.data,
                    sub1_head: res.data.data[0].name
                })
            }

        }).catch(error => {

        })
    }
    //渲染第一段
    initSub1() {
        if (this.state.sub1.length > 0) {
            return (
                this.state.sub1.map((item, index) => {
                    return (
                        (
                            <Fade bottom key={index}>
                                <MDBCol className="p-5 video-col">
                                    <MDBCard className='h-100'>
                                        <Slide top>
                                            <MDBCardImage
                                                src={BASE_URL + item.photo}
                                                alt=''
                                                position='top'
                                                className="video-card-img p-2"
                                            />
                                        </Slide>
                                        <MDBCardBody className="home-card-body">
                                            <Fade bottom cascade>
                                                <MDBCardText className="video-card-slide-text">
                                                    {item.content}
                                                </MDBCardText>
                                            </Fade >
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </Fade>
                        )
                    )
                })
            )
        }
    }
    //第二段
    getSub2 = async() => {
        get_video_sub2().then(res => {
            if(res.data.code === 200){
                this.setState({
                    sub2:res.data.data,
                    sub2_head:res.data.data[0].name
                })
            }
        }).catch(error => {

        })
    }
    //渲染第二段
    initSub2(){
        if(this.state.sub2.length > 0){
            return(
                (
                    <>
                    <MDBCol md='5' className="p-4 m-auto">
                                <Fade bottom>
                                    <MDBCard className='h-100 video-card'>
                                        <MDBCardImage
                                            src={BASE_URL+(this.state.sub2.length>=1?this.state.sub2[0].photo:"")}
                                            alt=''
                                            position='top'
                                        />
                                        <MDBCardOverlay>
                                            <MDBCardText className="video-card-over-text">
                                                {this.state.sub2.length>=1?this.state.sub2[0].content:"无数据"}
                                            </MDBCardText>
                                        </MDBCardOverlay>
                                    </MDBCard>
                                </Fade>
                    </MDBCol>
                    <MDBCol md='3' className="p-4 m-auto">
                        <Fade bottom>
                            <MDBCard className='h-100 video-card'>
                                <video style={{ borderRadius: '20px' }} 
                                    muted
                                    autoplay="autoplay"
                                    loop="loop" 
                                    src={BASE_URL+(this.state.sub2.length>=2?this.state.sub2[1].photo:"")}>
                                </video>
                                <MDBCardOverlay>
                                    <MDBCardText className="video-card-over-text">
                                        {this.state.sub2.length>=2?this.state.sub2[1].content:"无数据"}
                                    </MDBCardText>
                                </MDBCardOverlay>
                            </MDBCard>
                        </Fade>
                    </MDBCol>
                    <MDBCol md='3' className="p-4 m-auto">
                        <Fade bottom>
                            <MDBCard className='h-100 video-card'>
                                <video style={{ borderRadius: '20px' }} 
                                    autoplay="autoplay"
                                    loop="loop" 
                                    muted
                                    src={BASE_URL+(this.state.sub2.length>=3?this.state.sub2[2].photo:"")}>
                                </video>
                                <MDBCardOverlay>
                                    <MDBCardText className="video-card-over-text">
                                        {this.state.sub2.length>=3?this.state.sub2[2].content:"无数据"}
                                    </MDBCardText>
                                </MDBCardOverlay>
                            </MDBCard>
                        </Fade>
                    </MDBCol>
                    </>
                )
            )
        }
    }
    //第三段
    getSub3 = async () => {
        get_video_sub3().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    sub3: res.data.data,
                    sub3_head: res.data.data[0].name
                })
            }
        }).catch(error => {

        })
    }
    //渲染第三段
    initSub3() {
            if (this.state.sub3.length > 0) {
                return (
                    (
                        <>
                        <Fade bottom>
                            <MDBRow className="video-row-1">
                                <MDBCol size='6' md='2'>
                                    <MDBCard className="videdo-card-map">
                                        <MDBCardImage className="videdo-card-map-img p-2" src={BASE_URL+(this.state.sub3.length>=1?this.state.sub3[0].photo:"")} position='top' alt='...' />
                                        <MDBCardBody className="videdo-card-map-body">
                                            <MDBCardTitle className="videdo-card-map-title">{this.state.sub3.length>=1?this.state.sub3[0].title:"无数据"}</MDBCardTitle>
                                            <MDBCardText className="videdo-card-map-text">
                                                {this.state.sub3.length>=1?this.state.sub3[0].content:"无数据"}
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                                <MDBCol size='6' md='2'>
                                    <MDBCard className="videdo-card-map">
                                        <MDBCardImage className="videdo-card-map-img p-2" src={BASE_URL+(this.state.sub3.length>=2?this.state.sub3[1].photo:"")} position='top' alt='...' />
                                        <MDBCardBody className="videdo-card-map-body">
                                            <MDBCardTitle className="videdo-card-map-title">{this.state.sub3.length>=2?this.state.sub3[1].title:"无数据"}</MDBCardTitle>
                                            <MDBCardText className="videdo-card-map-text">
                                            {this.state.sub3.length>=2?this.state.sub3[1].content:"无数据"}
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                        </Fade>
                        <Fade bottom>
                            <MDBRow className="video-row-2 mt-3">
                                <MDBCol size='6' md='2'>
                                    <MDBCard className="videdo-card-map">
                                        <MDBCardImage className="videdo-card-map-img p-2" src={BASE_URL+(this.state.sub3.length>=3?this.state.sub3[2].photo:"")} position='top' alt='...' />
                                        <MDBCardBody className="videdo-card-map-body">
                                            <MDBCardTitle className="videdo-card-map-title">{this.state.sub3.length>=3?this.state.sub3[2].title:"无数据"}</MDBCardTitle>
                                            <MDBCardText className="videdo-card-map-text">
                                                {this.state.sub3.length>=3?this.state.sub3[2].content:"无数据"}
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                                <MDBCol size='6' md='2'>
                                    <MDBCard className="videdo-card-map">
                                        <MDBCardImage className="videdo-card-map-img p-2" src={BASE_URL+(this.state.sub3.length>=4?this.state.sub3[3].photo:"")} position='top' alt='...' />
                                        <MDBCardBody className="videdo-card-map-body">
                                            <MDBCardTitle className="videdo-card-map-title">{this.state.sub3.length>=4?this.state.sub3[3].title:"无数据"}</MDBCardTitle>
                                            <MDBCardText className="videdo-card-map-text">
                                                {this.state.sub3.length>=4?this.state.sub3[3].content:"无数据"}
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                        </Fade>
                        <Fade bottom>
                            <MDBRow className="video-row-3 mt-3">
                            <MDBCol size='6' md='2'>
                                    <MDBCard className="videdo-card-map">
                                        <MDBCardImage className="videdo-card-map-img p-2" src={BASE_URL+(this.state.sub3.length>=5?this.state.sub3[4].photo:"")} position='top' alt='...' />
                                        <MDBCardBody className="videdo-card-map-body">
                                            <MDBCardTitle className="videdo-card-map-title">{this.state.sub3.length>=5?this.state.sub3[4].title:"无数据"}</MDBCardTitle>
                                            <MDBCardText className="videdo-card-map-text">
                                                {this.state.sub3.length>=5?this.state.sub3[4].content:"无数据"}
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                                <MDBCol size='6' md='2'>
                                    <MDBCard className="videdo-card-map">
                                        <MDBCardImage className="videdo-card-map-img p-2" src={BASE_URL+(this.state.sub3.length>=6?this.state.sub3[5].photo:"")} position='top' alt='...' />
                                        <MDBCardBody className="videdo-card-map-body">
                                            <MDBCardTitle className="videdo-card-map-title">{this.state.sub3.length>=6?this.state.sub3[5].title:"无数据"}</MDBCardTitle>
                                            <MDBCardText className="videdo-card-map-text">
                                                {this.state.sub3.length>=6?this.state.sub3[5].content:"无数据"}
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                        </Fade>
                        <Fade bottom>
                            <MDBRow className="video-row-4 mt-3 mb-4">
                            <MDBCol size='6' md='2'>
                                    <MDBCard className="videdo-card-map">
                                        <MDBCardImage className="videdo-card-map-img p-2" src={BASE_URL+(this.state.sub3.length>=7?this.state.sub3[6].photo:"")} position='top' alt='...' />
                                        <MDBCardBody className="videdo-card-map-body">
                                            <MDBCardTitle className="videdo-card-map-title">{this.state.sub3.length>=7?this.state.sub3[6].title:"无数据"}</MDBCardTitle>
                                            <MDBCardText className="videdo-card-map-text">
                                                {this.state.sub3.length>=7?this.state.sub3[6].content:"无数据"}
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                                <MDBCol size='6' md='2'>
                                    <MDBCard className="videdo-card-map">
                                        <MDBCardImage className="videdo-card-map-img p-2" src={BASE_URL+(this.state.sub3.length>=8?this.state.sub3[7].photo:"")} position='top' alt='...' />
                                        <MDBCardBody className="videdo-card-map-body">
                                            <MDBCardTitle className="videdo-card-map-title">{this.state.sub3.length>=8?this.state.sub3[7].title:"无数据"}</MDBCardTitle>
                                            <MDBCardText className="videdo-card-map-text">
                                                {this.state.sub3.length>=8?this.state.sub3[7].content:"无数据"}
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                        </Fade>
                        </>
                    )
                )
            }
    }
    //第四段
    getSub4 = async () => {
        get_video_sub4().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    sub4: res.data.data,
                    sub4_head: res.data.data[0].name
                })
            }
        }).catch(error => {

        })
    }
    //渲染第四段
    initSub4() {
        if (this.state.sub4.length > 0) {
            return (
                this.state.sub4.map((item, index) => {
                    return (
                        (
                            <MDBCol size="6" md='3' className="p-5 video-flow-col" key={index}>
                                <Fade bottom>
                                    <MDBCard className='h-100 video-flow-card'>
                                        <MDBCardImage
                                            src={BASE_URL + item.photo}
                                            alt='...'
                                            position='top'
                                            className="p-5 video-flow-card-img"
                                        />
                                        <MDBCardBody>
                                            <MDBCardTitle>{item.content}</MDBCardTitle>
                                        </MDBCardBody>
                                    </MDBCard>
                                </Fade>
                            </MDBCol>
                        )
                    )
                })
            )
        }
    }
    render() {
        return (
            <>
                <MDBRow style={{ margin: '0' }}>
                    <MDBCol md={12} style={{ padding: '0' }}>
                        <img alt='...' src={this.state.video_banner} className="video-banner-img" style={{ width: '100%', minHeight: '210px', objectFit: 'cover',backgroundRepeat:'no-repeat',backgroundSize:'cover' }} />
                    </MDBCol>
                </MDBRow>
                {/* 数字人短视频系统 */}
                <ScrollOverPack
                    id="video-data"
                    className="video-data"
                    appear={false}
                >
                    <QueueAnim key="video-data" leaveReverse type="bottom">
                        <MDBContainer key="video-data0" className="video-data-container mt-4 d-flex mb-4">
                            <TweenOne className="tween-one" key="video-data1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-4">
                                    <MDBCol md="12">
                                        <Fade bottom cascade>
                                            <div className="live-clone-title-wrapper mb-3">
                                                <h1 className="mb-3">
                                                    {this.state.sub1_head}
                                                </h1>
                                            </div>
                                        </Fade>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
                                {this.initSub1()}
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
                {/* 技术背景 */}
                <ScrollOverPack
                    id="video-tec"
                    className="video-tec"
                    appear={false}
                >
                    <QueueAnim key="video-tec" leaveReverse type="bottom">
                        <MDBContainer key="video-tec0" className="video-tec-container mt-2 d-flex mb-4">
                            <TweenOne className="tween-one" key="video-tec1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-2">
                                    <MDBCol md="12">
                                        <Fade bottom cascade>
                                            <div className="live-clone-title-wrapper mb-3">
                                                <h1 className="mb-3">
                                                    {this.state.sub2_head}
                                                </h1>
                                            </div>
                                        </Fade>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow>
                                {this.initSub2()}
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
                {/* 功能地图 */}
                <ScrollOverPack
                    id="video-map"
                    className="video-map"
                    appear={false}
                >
                    <QueueAnim key="video-map" leaveReverse type="bottom">
                        <MDBContainer key="video-map0" className="video-tec-container mt-2 d-flex mb-2">
                            <TweenOne className="tween-one" key="video-map1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-4">
                                    <MDBCol md="12">
                                        <Fade bottom cascade>
                                            <div className="live-clone-title-wrapper mb-3">
                                                <h1 className="mb-3">
                                                    {this.state.sub3_head}
                                                </h1>
                                            </div>
                                        </Fade>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow>
                                <MDBCol md='12'>
                                    <MDBCard>
                                        <Fade bottom>
                                            {this.initPhoto()}
                                        </Fade>
                                        <MDBCardOverlay>
                                            {this.initSub3()}
                                        </MDBCardOverlay>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
                {/* 短视频制作流程 */}
                < ScrollOverPack
                    id="video-flow"
                    className="video-flow"
                    appear={false}
                    playScale={0.3}
                >
                    <QueueAnim key="video-flow0" leaveReverse type="bottom">
                        <MDBContainer key="video-flow1" className="home-news mt-4 d-flex">
                            <TweenOne className="tween-one" key="video-flow2" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-2">
                                    <MDBCol md="12">
                                        <div className="title-wrapper">
                                            <h1>
                                                <span>{this.state.sub4_head}</span>
                                            </h1>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow>
                                {this.initSub4()}
                            </MDBRow>
                            <MDBRow>
                                {this.initFooter()}
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
            </>
        );
    }
}