import React, { Component } from "react";
import {
    MDBContainer,
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBRow,
    MDBCol
} from "mdb-react-ui-kit";
import { Slide, Zoom } from 'react-reveal';
import QueueAnim from 'rc-queue-anim';
import ScrollAnim from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import 'rc-texty/assets/index.css';
import agencyBanner from '../../assets/banner1.png';
import '../agency/agency.css';
import { get_agency_support, get_agency_flow, get_agency_banner } from "../../axios/Api";
import { BASE_URL } from "../../axios/config";

const ScrollOverPack = ScrollAnim.OverPack;

export default class Agency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agency_support: [],
            agency_flow: [],
            agency_support_headTitle: '',
            agency_flow_headTitle: '',
            agency_banner:''
        }
        this.handleResize = this.handleResize.bind(this);
    }
    componentWillUnmount() {
        window.addEventListener('resize', this.handleResize);
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        document.body.scrollIntoView();
        this.getFlow();
        this.getSupport();
        this.getBanner();
    }
    //监听屏幕变化
    handleResize() {
        if (
            //判断移动端隐藏登录及注册按钮
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)
        ) {

        } else {

        }
    }
    //获取合作支持数据
    getSupport = async () => {
        get_agency_support().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    agency_support: res.data.data,
                    agency_support_headTitle: res.data.data[0].name
                })
            }
        }).catch(error => {

        })
    }
    //获取合作流程
    getFlow = async () => {
        get_agency_flow().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    agency_flow: res.data.data,
                    agency_flow_headTitle: res.data.data[0].name
                })
            }
        }).catch(error => {

        })
    }
    //获取横幅
    getBanner = async () => {
        get_agency_banner().then(res => {
            if(res.data.code === 200){
                if(res.data.data.image_src.length > 0){
                    this.setState({
                        agency_banner:BASE_URL+res.data.data.image_src.replace(/\\/, '/')
                    })
                }else{
                    this.setState({
                        agency_banner:agencyBanner
                    })
                }
            }
        }).catch(error => {

        })
    }
    render() {
        return (
            <>
                <MDBRow style={{ margin: '0' }}>
                    <MDBCol md={12} style={{ padding: '0' }}>
                        <img alt='...' src={this.state.agency_banner} className="livevh-banner-img" style={{ width: '100%', minHeight: '210px', objectFit: 'cover',backgroundRepeat:'no-repeat',backgroundSize:'cover' }} />
                    </MDBCol>
                </MDBRow>
                {/* 合作支持 */}
                <ScrollOverPack
                    id="agency-support"
                    className="real-ser"
                    appear={false}
                >
                    <QueueAnim key="agency" leaveReverse type="bottom">
                        <MDBContainer key="agency0" className="home-news mt-4 d-flex">
                            <TweenOne className="tween-one" key="agency1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-4">
                                    <MDBCol md="12">
                                        <Zoom bottom cascade>
                                            <div className="agency-support-title-wrapper mb-3">
                                                <h1>
                                                    {this.state.agency_support_headTitle}
                                                </h1>
                                            </div>
                                        </Zoom>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
                                {
                                    this.state.agency_support.map((item, index) => {
                                        return (
                                            (
                                                <Slide bottom key={index}>
                                                    <MDBCol className="support-col p-5">
                                                        <MDBCard className='h-100 support-card'>
                                                            <Slide bottom>
                                                                <MDBCardImage
                                                                    src={BASE_URL+item.img_src}
                                                                    alt='...'
                                                                    position='top'
                                                                    className="support-card-img p-5"
                                                                />
                                                            </Slide>
                                                            <MDBCardBody>
                                                                <Slide right>
                                                                    <MDBCardTitle className="support-card-title">
                                                                        {item.title}
                                                                    </MDBCardTitle>
                                                                </Slide>
                                                                <Zoom right cascade>
                                                                    <MDBCardText className="support-card-text">
                                                                        {item.content}
                                                                    </MDBCardText>
                                                                </Zoom>
                                                            </MDBCardBody>
                                                        </MDBCard>
                                                    </MDBCol>
                                                </Slide>
                                            )
                                        )
                                    })
                                }
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack >
                {/* 合作流程 */}
                < ScrollOverPack
                    id="work-flow"
                    className="work-flow"
                    appear={false}
                    playScale={0.3}
                >
                    <QueueAnim key="flow0" leaveReverse type="bottom">
                        <MDBContainer key="0" className="home-news mt-3 d-flex">
                            <TweenOne className="tween-one" key="0" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-2">
                                    <MDBCol md="12">
                                        <div className="title-wrapper">
                                            <h1 name="title">
                                                <span>{this.state.agency_flow_headTitle}</span>
                                            </h1>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow>
                                {
                                    this.state.agency_flow.map((item, index) => {
                                        return (
                                            (
                                                <MDBCol key={index} size="6" xl={3} className="p-5 support-col">
                                                    <Slide bottom>
                                                        <MDBCard className='h-100 work-flow-card'>
                                                            <Slide bottom>
                                                                <MDBCardImage
                                                                    src={BASE_URL + item.img_src}
                                                                    alt='...'
                                                                    position='top'
                                                                    className="p-5 work-flow-card-img"
                                                                />
                                                            </Slide>
                                                            <MDBCardBody>
                                                                <MDBCardTitle>{item.content}</MDBCardTitle>
                                                            </MDBCardBody>
                                                        </MDBCard>
                                                    </Slide>
                                                </MDBCol>
                                            )
                                        )
                                    })
                                }
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
            </>
        )
    }
}