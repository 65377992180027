import React, { Component } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardImage,
  MDBContainer,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';
import { Fade, Zoom, Roll, Bounce, Slide } from 'react-reveal';
import QueueAnim from 'rc-queue-anim';
import ScrollAnim from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import 'rc-texty/assets/index.css';
import aboutus_banner from '../../assets/banner2.png';
import '../about/aboutus.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { Autoplay, EffectCoverflow } from 'swiper/modules';
import { get_company_desc, getCompany_culture } from "../../axios/Api";
import { BASE_URL } from "../../axios/config";

const ScrollOverPack = ScrollAnim.OverPack;
export default class Aboutus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_desc: [],
      company_culture: [],
      title1:''
    }
  };
  componentDidMount() {
    document.body.scrollIntoView();
    this.getCompanyDesc();
    this.getCompanyCulture();
  }
  //获取公司简介
  getCompanyDesc = async () => {
    get_company_desc().then(res => {
      // console.log(res.data);
      if (res.data.code === 200) {
        this.setState({
          company_desc: res.data.data
        })
      }
    }).catch(error => {

    })
  }
  //获取企业文化
  getCompanyCulture = async () => {
    getCompany_culture().then(res => {
      // console.log(res.data);
      if (res.data.code === 200) {
        this.setState({
          company_culture: res.data.data,
          title1:res.data.data[0].title
        })
      }
    }).catch(error => {

    })
  }
  render() {
    return (
      <>
        <MDBRow style={{ margin: '0' }}>
          <MDBCol md={12} style={{ padding: '0' }}>
            <img alt='...' src={BASE_URL+(this.state.company_desc.company_banner !=="" ?this.state.company_desc.company_banner:aboutus_banner)} className="aboutus-banner-img" style={{ width: '100%', minHeight: '210px', objectFit: 'cover',backgroundRepeat:'no-repeat',backgroundSize:'cover' }} />
          </MDBCol>
        </MDBRow>
        {/* 公司简介 */}
        <ScrollOverPack
          id="company-des"
          className="company-des"
          appear={false}
        >
          <QueueAnim key="k0" leaveReverse type="bottom">
            <MDBContainer key="k1" className="aboutus-container mt-4 d-flex mb-4">
              <TweenOne className="tween-one" key="k2" animation={{ opacity: 1 }}>
                <MDBRow className="mb-2">
                  <MDBCol md="12">
                    <Zoom bottom cascade>
                      <div className="title-wrapper mb-3">
                        <h1>
                          {this.state.company_desc.title !== ""?this.state.company_desc.title:""}
                        </h1>
                      </div>
                    </Zoom>
                    <Bounce bottom cascade>
                        <Slide bottom>
                        <div dangerouslySetInnerHTML={{ __html: this.state.company_desc.company_maindes !== "" ?this.state.company_desc.company_maindes:"" }}></div>
                        </Slide>
                      <br />
                      <Slide bottom>
                      <div dangerouslySetInnerHTML={{ __html: this.state.company_desc.company_secdes !== "" ?this.state.company_desc.company_secdes:"" }}></div>
                      </Slide>
                    </Bounce>
                  </MDBCol>
                </MDBRow>
              </TweenOne>
              <div className="d-flex flex-row show-img-center mt-1">
                <div className="p-1">
                  <Fade right>
                    <MDBCard className='h-100 home-card-dz'>
                      <Zoom left cascade>
                        <MDBCardImage
                          src={BASE_URL + this.state.company_desc.company_pic}
                          alt=''
                          position='top'
                        />
                      </Zoom>
                    </MDBCard>
                  </Fade>
                </div>
              </div>
            </MDBContainer>
          </QueueAnim>
        </ScrollOverPack>
        {/* 企业文化 */}
        <ScrollOverPack
          id="culture"
          className="real-ser"
          appear={false}
        >
          <QueueAnim key="k3" leaveReverse type="bottom">
            <MDBContainer key="k4" className="home-news mt-2 d-flex">
              <TweenOne className="tween-one" key="k5" animation={{ opacity: 1 }}>
                <MDBRow className="mb-2">
                  <MDBCol md="12">
                    <Zoom bottom cascade>
                      <div className="culture-title-wrapper mb-3">
                        <h1>
                          {this.state.title1}
                        </h1>
                      </div>
                    </Zoom>
                  </MDBCol>
                </MDBRow>
              </TweenOne>
              <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, EffectCoverflow]}
                className="swiper"
              >
                <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
                  {
                    this.state.company_culture.map((item, index) => {
                      return (
                        (
                          <MDBCol key={index}>
                            <SwiperSlide key={index}>
                              <Fade bottom>
                                <MDBCard className='h-100'>
                                  <Roll left>
                                    <MDBCardImage
                                      src={BASE_URL+item.img_src}
                                      alt='...'
                                      position='top'
                                    />
                                  </Roll>
                                  <MDBCardBody>
                                    <Fade top>
                                      <MDBCardText>
                                        {item.img_text}
                                      </MDBCardText>
                                    </Fade>
                                  </MDBCardBody>
                                </MDBCard>
                              </Fade>
                            </SwiperSlide>
                          </MDBCol>
                        )
                      )
                    })
                  }
                </MDBRow>
              </Swiper>
            </MDBContainer>
          </QueueAnim>
        </ScrollOverPack>
      </>
    );
  }
}