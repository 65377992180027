import instance from "./request";

//首页
//获取关键字等meta信息
export const get_meta_info = () => {
    return instance.request({
        url:'/api/WebApi/get_metas',
        method:'post'
    });
}
//获取导航栏
export const getNavs = () => {
    return instance.request({
        url:'/api/WebApi/get_nav_links',
        method:'post'
    });
}
//获取轮播图
export const getHomeBanner = (params: any) => {
    return instance.request({
        url:'/api/WebApi/get_home_banner',
        method:'post',
        params
    });
};
//获取产品与服务数据
export const getProServer = (params  :any) => {
    return instance.request({
        url:'/api/WebApi/get_pro_ser',
        method:'post',
        params
    })
}
//获取真人定制服务
export const getRealServer = (params  :any) => {
    return instance.request({
        url:'/api/WebApi/get_real_ser',
        method:'post',
        params
    })
}
//获取footer信息
export const getFooterAll = (params  :any) => {
    return instance.request({
        url:'/api/WebApi/get_site_all',
        method:'post',
        params
    })
}
//获取解决方案链接
export const get_solution_links = (params  :any) => {
    return instance.request({
        url:'/api/WebApi/get_solution_links',
        method:'post',
        params
    })
}
//获取数字人定制数据
export const get_dhcustom = () => {
    return instance.request({
        url:'/api/WebApi/get_dhcustom',
        method:'post'
    })
}


//数字人聊天api
//保存聊天信息
export const insert_chat_data = (data:any) => {
    return instance.request({
        url:'/api/WebApi/insert_chat_data',
        method:'post',
        data
    })
}
//返回最新10条数据
export const get_chat_top5 = () => {
    return instance.request({
        url:'/api/WebApi/get_chat_top5',
        method:'post'
    })
}

//虚拟人直播
//获取横幅图片
export const get_livevh_banner = () =>{
    return instance.request({
        url:'/api/WebApi/get_livevh_banner',
        method:'post'
    })
}
//基本信息
export const get_livevh_base = () => {
    return instance.request({
        url:'/api/WebApi/get_livevh_base',
        method:'post'
    })
}
//基础数据下第一小段
export const get_livevh_base_sub1 = () => {
    return instance.request({
        url:'/api/WebApi/get_livevh_base_sub1',
        method:'post'
    })
}
//基础数据下第二小段
export const get_livevh_base_sub2 = () => {
    return instance.request({
        url:'/api/WebApi/get_livevh_base_sub2',
        method:'post'
    })
}
//第一段
export const get_livevh_sub1 = () => {
    return instance.request({
        url:'/api/WebApi/get_livevh_sub1',
        method:'post'
    })
}
//第二段
export const get_livevh_sub2 = () => {
    return instance.request({
        url:'/api/WebApi/get_livevh_sub2',
        method:'post'
    })
}
//第三段
export const get_livevh_sub3 = () => {
    return instance.request({
        url:'/api/WebApi/get_livevh_sub3',
        method:'post'
    })
}

//新闻动态
//获取新闻类型
export const get_news_types = () =>{
    return instance.request({
        url:'/api/WebApi/get_news_types',
        method:'post'
    })
}
//新闻动态横幅
export const get_news_banner = () => {
    return instance.request({
        url:'/api/WebApi/get_news_banner',
        method:'post'
    })
}
//获取类型列表下所有新闻标题
export const get_news_title = (data:any) => {
    return instance.request({
        url:'/api/WebApi/get_news_title',
        method:'post',
        data
    })
}
//获取最新一条新闻
export const get_news_top1 = () => {
    return instance.request({
        url:'/api/WebApi/get_news_top1',
        method:'post'
    })
}
//获取新闻详细内容，根据新闻id获取
export const get_news_content = (data:any) => {
    return instance.request({
        url:'/api/WebApi/get_news_content',
        method:'post',
        data
    })
}



//帮助中心
//获取帮助中心类型
export const get_help_types = () => {
    return instance.request({
        url:'/api/WebApi/get_help_type',
        method:'post'
    })
}
//帮助中横幅
export const get_help_banner = () => {
    return instance.request({
        url:'/api/WebApi/get_help_banner',
        method:'post'
    })
}
//获取帮助中心最新一条
export const get_help_top1 = () => {
    return instance.request({
        url:'/api/WebApi/get_help_top1',
        method:'post'
    })
}
//根据类型加载对应数据
export const get_help_data_by_typeid = (data  :any) => {
    return instance.request({
        url:'/api/WebApi/get_help_type_byId',
        method:'post',
        data
    })
}
//根据id加载数据
export const get_help_content_byid = (data:any) => {
    return instance.request({
        url:'/api/WebApi/get_help_content_byId',
        method:'post',
        data
    })
}

//关于我们
//获取公司简介
export const get_company_desc = () => {
    return instance.request({
        url:'/api/WebApi/get_aboutus_company_desc',
        method:'post'
    })
}
//获取企业文化
export const getCompany_culture = () => {
    return instance.request({
        url:'/api/WebApi/get_aboutus_culture',
        method:'post'
    })
}

//代理招募
//合作支持
export const get_agency_support = () => {
    return instance.request({
        url:'/api/WebApi/get_support',
        method:'post'
    })
}
//合作流程
export const get_agency_flow = () => {
    return instance.request({
        url:'/api/WebApi/get_work_flow',
        method:'post'
    })
}
//代理招募横幅
export const get_agency_banner = () => {
    return instance.request({
        url:'/api/WebApi/get_work_banner',
        method:'post'
    })
}


//定制服务
//横幅
export const get_server_banner = () => {
    return instance.request({
        url:'/api/WebApi/get_server_banner',
        method:'post'
    })
}
//基本信息
export const get_server_base = () => {
    return instance.request({
        url:'/api/WebApi/get_server_base',
        method:'post'
    })
}
//获取第一段内容数据
export const get_server_sub1 = () => {
    return instance.request({
        url:'/api/WebApi/get_server_sub1',
        method:'post'
    })
}
//第二段
export const get_server_sub2 = () => {
    return instance.request({
        url:'/api/WebApi/get_server_sub2',
        method:'post'
    })
}
//获取第三段内容数据
export const get_server_sub3 = () => {
    return instance.request({
        url:'/api/WebApi/get_server_sub3',
        method:'post'
    })
}
//获取第四段内容数据
export const get_server_sub4 = () => {
    return instance.request({
        url:'/api/WebApi/get_server_sub4',
        method:'post'
    })
}


//虚拟人短视频
//横幅
export const get_video_banner = () => {
    return instance.request({
        url:'/api/WebApi/get_video_banner',
        method:'post'
    })
}
//基本信息
export const get_video_base = () => {
    return instance.request({
        url:'/api/WebApi/get_video_base',
        method:'post'
    })
}
//第一段
export const get_video_sub1 = () => {
    return instance.request({
        url:'/api/WebApi/get_video_sub1',
        method:'post'
    })
}
//第二段
export const get_video_sub2 = () => {
    return instance.request({
        url:'/api/WebApi/get_video_sub2',
        method:'post'
    })
}
//第三段
export const get_video_sub3 = () => {
    return instance.request({
        url:'/api/WebApi/get_video_sub3',
        method:'post'
    })
}
//第四段
export const get_video_sub4 = () => {
    return instance.request({
        url:'/api/WebApi/get_video_sub4',
        method:'post'
    })
}


//无限播智能版
//横幅
export const get_ivl_banner = () => {
    return instance.request({
        url:'/api/WebApi/get_ivl_banner',
        method:'post'
    })
}
//获取基本信息
export const get_ivl_base = () => {
    return instance.request({
        url:'/api/WebApi/get_ivl_base',
        method:'post'
    })
}
//获取介绍信息
export const get_ivl_sub1 = () => {
    return instance.request({
        url:'/api/WebApi/get_ivl_sub1',
        method:'post'
    })
}

//app客户端
//轮播图
export const get_app_banner = () => {
    return instance.request({
        url:'/api/WebApi/get_app_banner',
        method:'post'
    })
}
//基本信息
export const get_app_base = () => {
    return instance.request({
        url:'/api/WebApi/get_app_base',
        method:'post'
    })
}
//第一段
export const get_app_sub1 = () => {
    return instance.request({
        url:'/api/WebApi/get_app_sub1',
        method:'post'
    })
}
//第二段
export const get_app_sub2 = () => {
    return instance.request({
        url:'/api/WebApi/get_app_sub2',
        method:'post'
    })
}
//按钮
export const get_app_btn = () => {
    return instance.request({
        url:'/api/WebApi/get_app_btn',
        method:'post'
    })
}