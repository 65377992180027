import React, { Component } from "react";
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBCardImage,
    MDBCardText,
    MDBTooltip
} from "mdb-react-ui-kit";
import './client.css';
import { Slide, Fade } from 'react-reveal';
import BannerAnim, { Element } from 'rc-banner-anim';
import QueueAnim from "rc-queue-anim";
import ScrollAnim from 'rc-scroll-anim';
import TweenOne from "rc-tween-one";
import 'rc-banner-anim/assets/index.css';
import { BASE_URL } from "../../axios/config";
import {
    get_app_banner,
    get_app_base,
    get_app_sub1,
    get_app_sub2,
    get_app_btn
} from "../../axios/Api";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { Autoplay, EffectCoverflow } from 'swiper/modules';
const BgElement = Element.BgElement;
const ScrollOverPack = ScrollAnim.OverPack;

//随机动画
const anmia = ['alpha', 'left', 'right', 'top', 'bottom', 'scale', 'scaleBig', 'scaleX', 'scaleY'];
const random = (arr) => arr[Math.floor(Math.random() * arr.length)];
const randomAn = random(anmia);
export default class Client extends Component {
    constructor(props) {
        super(props);
        this.state = {
            app_base_content: '',
            app_btn: [],
            app_sub1: [],
            app_sub1_title: '',
            app_sub2: [],
            app_sub2_title: '',
            app_sub2_subtitle: '',
            app_banner: []
        }
    }
    componentDidMount() {
        this.getBanner();
        this.getBaseInfo();
        this.getBtnInfo();
        this.getAppSub1();
        this.getAppSub2();
        document.body.scrollIntoView();
    }
    //获取轮播图
    getBanner = async () => {
        get_app_banner().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    app_banner: res.data.data
                })
            }
        }).catch(error => {

        })
    }
    //渲染轮播图
    initBanner() {
        if (this.state.app_banner.length > 0) {
            return (
                this.state.app_banner.map((item, index) => {
                    return (
                        <Element
                            prefixCls="banner-app-elem"
                            key={index}
                        >
                            <BgElement
                                key="bg"
                                className="bg"
                                style={{
                                    backgroundImage: 'url(' + BASE_URL + item.banner_path.replace(/\\/, '/') + ')'
                                }}
                            />
                            <QueueAnim className="banner-app-wrapper"
                                key="QueueAnim"
                                type={randomAn}
                                delay={200}
                            >
                            <Fade top>
                                <div className="banner-app-title">
                                    <h3>
                                            {item.banner_title}
                                    </h3>
                                </div>
                            </Fade>
                            <Fade bottom>
                                <div className="banner-app-content">
                                    <span>
                                        <Fade bottom>
                                            {item.banner_content}
                                        </Fade>
                                    </span>
                                </div>
                            </Fade>
                            </QueueAnim>
                        </Element>
                    )
                })
            )
        }
    }
    //获取基本信息
    getBaseInfo = async () => {
        get_app_base().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    app_base_content: res.data.data.content
                })
            }
        }).catch(error => {

        });
    }
    //获取第一段
    getAppSub1 = async () => {
        get_app_sub1().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    app_sub1: res.data.data,
                    app_sub1_title: res.data.data[0].name
                })
            }
        }).catch(error => {

        })
    }
    //渲染第一段
    initSub1() {
        if (this.state.app_sub1.length > 0) {
            return (
                this.state.app_sub1.map((item, index) => {
                    return (
                        <MDBCol md="3" xl={3} className="p-5 sub1-col" key={index}>
                            <MDBCard className='h-100 sub1-card'>
                                <Slide top>
                                    <MDBCardImage
                                        src={BASE_URL + item.photo}
                                        alt=''
                                        className="sub1-img"
                                    />
                                </Slide>
                                <MDBCardBody>
                                    <Slide top>
                                        <MDBCardTitle>
                                            {item.title}
                                        </MDBCardTitle>
                                    </Slide>
                                    <Slide bottom>
                                        <MDBCardText className="ser-card-slide-text">
                                            {item.content}
                                        </MDBCardText>
                                    </Slide >
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    )
                })
            )
        }
    }
    //获取第二段
    getAppSub2 = async () => {
        get_app_sub2().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    app_sub2: res.data.data,
                    app_sub2_title: res.data.data[0].name,
                    app_sub2_subtitle: res.data.data[0].sub_name
                })
            }
        }).catch(error => {

        })
    }
    //渲染第二段
    initSub2(){
        if(this.state.app_sub2.length > 0){
            return(
                this.state.app_sub2.map((item,index) => {
                    return(
                        <SwiperSlide key={index}>
                            <Fade bottom>
                                <div className="ratio ratio-1x1">
                                    <video autoplay="autoplay" loop="loop" muted src={BASE_URL+item.video}>
                                    </video>
                                </div>
                            </Fade>
                        </SwiperSlide>
                    )
                })
            )
        }
    }
    //获取按钮信息
    getBtnInfo = async () => {
        get_app_btn().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    app_btn: res.data.data
                })
            }
        }).catch(error => {

        })
    }

    render() {
        return (
            <>
                <BannerAnim prefixCls="banner-app" autoPlay>
                    {this.initBanner()}
                </BannerAnim>
                {/* 产品优势 */}
                <ScrollOverPack
                    id="app-advantage"
                    className="app-advantage"
                    appear={false}
                >
                    <QueueAnim key="pro" leaveReverse type="bottom">
                        <MDBContainer key="ser0" className="service-container mt-4 d-flex mb-2">
                            <TweenOne className="tween-one" key="pro1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-4">
                                    <MDBCol md="12">
                                        <div className="live-clone-title-wrapper mb-1">
                                            <Slide bottom>
                                                <h1>
                                                    {this.state.app_sub1_title}
                                                </h1>
                                            </Slide>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow>
                                {this.initSub1()}
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>

                {/* 产品展示 */}
                <ScrollOverPack
                    id="app-show"
                    className="app-show"
                    appear={false}
                >
                    <QueueAnim key="pro" leaveReverse type="bottom">
                        <MDBContainer key="pro0" className="home-news mt-1 d-flex">
                            <TweenOne className="tween-one p-3" key="show1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-2">
                                    <MDBCol md="12">
                                        <div className="live-clone-title-wrapper mb-3">
                                            <Slide bottom>
                                                <h1 className="mb-4 mt-2">
                                                    {this.state.app_sub2_title}
                                                </h1>
                                            </Slide>
                                            <Slide bottom>
                                                <MDBCardText style={{ textAlign: 'left' }}>
                                                    {this.state.app_sub2_subtitle}
                                                </MDBCardText>
                                            </Slide>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <Swiper
                                effect={'coverflow'}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={'auto'}
                                coverflowEffect={{
                                    rotate: 50,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 0,
                                    slideShadows: false,
                                }}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, EffectCoverflow]}
                                className="swiper client-swiper"
                            >
                                {this.initSub2()}
                            </Swiper>
                            <MDBCol className="p-3">
                                <Fade bottom>
                                    <MDBCardText style={{ textAlign: 'left' }}>
                                        {this.state.app_base_content}
                                    </MDBCardText>
                                </Fade>
                            </MDBCol>
                            <MDBCol className="client-download mb-4">
                                <Fade bottom>
                                    <MDBTooltip
                                        tag='a'
                                        wrapperProps={{ color: 'secondary', href: this.state.app_btn.link,download:'wxb.apk' }} placement='bottom'
                                        title={
                                            <>
                                                <img
                                                    src={BASE_URL + this.state.app_btn.qr}
                                                    className='img-thumbnail'
                                                    alt='...'
                                                />
                                            </>
                                        }
                                        wrapperClass='mt-3 btn btn-info ivl-download btn-rounded'
                                    >
                                            <MDBIcon fab icon="android" className="me-2" />
                                            {this.state.app_btn.name}
                                    </MDBTooltip>
                                </Fade>
                            </MDBCol>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
            </>
        )
    }
}

