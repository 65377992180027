import Home from "../pages/home/Home";
import Livevh from "../pages/livevh/Livevh";
import Ivl from "../pages/ivl/Ivl";
import Video from "../pages/video/Video";
import Aboutus from "../pages/about/Aboutus";
import News from "../pages/news/News";
import Service from "../pages/service/Service";
import Agency from "../pages/agency/Agency";
import Help from "../pages/help/Help";
import Client from "../pages/client/Client";
import { getNavs } from "../axios/Api";

let routes = [
    {
        path: '/',
        name: 'home',
        label: '首页',
        display: 'none',
        element: <Home />
    },
    {
        path: '/livevh',
        name: 'livevh',
        label: '虚拟人直播',
        display: 'none',
        element: <Livevh />
    },
    {
        path: '/ivl',
        name: 'ivl',
        label: '无限播智能版',
        display: 'none',
        element: <Ivl />
    },
    {
        path: '/client',
        name: 'client',
        label: '客户端',
        display: 'none',
        element: <Client />
    },
    {
        path: '/video',
        name: 'video',
        label: '虚拟人短视频',
        display: 'none',
        element: <Video />
    },
    {
        path: '/service',
        name: 'service',
        label: '定制服务',
        display: 'none',
        element: <Service />
    },
    {
        path: '/news',
        name: 'news',
        label: '新闻动态',
        display: 'none',
        element: <News />
    },
    {
        path: '/aboutus',
        name: 'aboutus',
        label: '关于我们',
        display: 'none',
        element: <Aboutus />
    },
    {
        path: '/help',
        name: 'help',
        label: '帮助中心',
        display: 'none',
        element: <Help />
    },
    {
        path: '/agency',
        name: 'agency',
        label: '代理招募',
        display: 'none',
        element: <Agency />
    }
]
function getNavLinks() {
    getNavs().then(res => {
        if (res.data.code === 200) {
            for (let r = 0; r < res.data.data.length; r++) {
                for (let i = 0; i < routes.length; i++) {
                    if (res.data.data[r].router_name === routes[i].name) {
                        if (res.data.data[r].router_status === 0) {
                            routes[i].display = 'block';
                        } else {
                            routes[i].display = 'none';
                        }
                        routes[i].label = res.data.data[r].router_label;
                    }
                }
            }
        }
    }).catch(error => {
    })
}

getNavLinks();
export default routes;