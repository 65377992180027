import React, { Component } from "react";
import {
    MDBRow,
    MDBCol,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBTypography,
    MDBIcon,
    MDBTooltip
} from 'mdb-react-ui-kit';
import QueueAnim from "rc-queue-anim";
import ScrollAnim from 'rc-scroll-anim';
import '../ivl/ivl.css';
import ivlBanner from '../../assets/banner2.png';
import { Slide } from 'react-reveal';
import { get_ivl_banner, get_ivl_base, get_ivl_sub1 } from "../../axios/Api";
import { BASE_URL } from "../../axios/config";
const ScrollOverPack = ScrollAnim.OverPack;
export default class Ivl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ivl_banner: '',
            ivl_base: [],
            mainContent: [],
            ivl_introduce: []
        }
    }
    componentDidMount() {
        document.body.scrollIntoView();
        this.getBanner();
        this.getBase();
        this.getIntroduceInfo();
    }
    //获取横幅
    getBanner = async () => {
        get_ivl_banner().then(res => {
            if (res.data.code === 200) {
                if (res.data.data.image_src.length > 0) {
                    this.setState({
                        ivl_banner: BASE_URL + res.data.data.image_src.replace(/\\/, '/')
                    })
                } else {
                    this.setState({
                        ivl_banner: ivlBanner
                    })
                }
            }
        }).catch(error => {

        })
    }
    //获取基本数据
    getBase = async () => {
        get_ivl_base().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    ivl_base: res.data.data,
                    mainContent: res.data.data.main_content.split('#')
                })
            }
        }).catch(error => {

        })
    }
    //获取介绍信息
    getIntroduceInfo = async () => {
        get_ivl_sub1().then(res => {
            if (res.data.code === 200) {
                let info = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    info.push({
                        title: res.data.data[i].title,
                        mediaSrc: res.data.data[i].media_src,
                        content: res.data.data[i].content.split('#')
                    })
                }
                this.setState({
                    ivl_introduce: info
                })
            }
        }).catch(error => {

        });
    }
    render() {
        return (
            <>
                <ScrollOverPack
                    id="whativl"
                    appear={false}
                    style={{ backgroundImage: 'url(' + this.state.ivl_banner + ')', height: '100vh', width: '100%' }}
                >
                    <QueueAnim key="ivl1" leaveReverse type="bottom">
                        <MDBRow className="ivl-banner-img">
                            <MDBCol md='12'>
                                <MDBRow className="ivl-row-video">
                                    <MDBCol md='7' className="m-4 p-3">
                                        <Slide bottom>
                                            <MDBCard>
                                                <video position='top' controls="controls" muted className="ivl-video-1" autoplay="autoplay" loop="loop" src={BASE_URL + this.state.ivl_base.media_src}>
                                                </video>
                                            </MDBCard>
                                        </Slide>
                                    </MDBCol>
                                    <MDBCol md='4' className="m-4 p-3">
                                        <Slide bottom>
                                            <MDBCard className="ivl-row-col-card">
                                                <MDBCardBody>
                                                    <Slide bottom>
                                                        <MDBCardTitle className="ivl-row-col-card-title">{this.state.ivl_base.main_title}</MDBCardTitle>
                                                    </Slide>
                                                    <MDBTypography listUnStyled className='mb-0 ivl-row-col-card-text'>
                                                        {
                                                            this.state.mainContent.map((item, index) => {
                                                                return (
                                                                    (
                                                                        <Slide key={index}>
                                                                            <li className='mb-1'>
                                                                                <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{item}
                                                                            </li>
                                                                        </Slide>
                                                                    )
                                                                )
                                                            })
                                                        }
                                                    </MDBTypography>
                                                    <Slide bottom>
                                                        <MDBTooltip
                                                            tag='a'
                                                            wrapperProps={{ color: 'secondary', href: this.state.ivl_base.button_link }} placement='bottom'
                                                            title={
                                                                <>
                                                                    <MDBTypography listUnStyled>
                                                                        <h5>配置要求</h5>
                                                                        <li>
                                                                            处理器:Intel-I5 12400F以上
                                                                            AMD-R5 5500以上
                                                                        </li>
                                                                        <li>
                                                                            内存:DDR4 16GB以上
                                                                        </li>
                                                                        <li>
                                                                            显卡:英伟达RTX3060以上
                                                                        </li>
                                                                        <li>
                                                                            <b>系统:</b>windows10 1903以上
                                                                        </li>
                                                                    </MDBTypography>
                                                                </>
                                                            }
                                                            wrapperClass='mt-3 btn btn-info btn-lg ivl-download btn-rounded'
                                                            >
                                                            <MDBIcon fab icon="windows" className="me-2" />
                                                            {this.state.ivl_base.button_name}
                                                        </MDBTooltip>
                                                    </Slide>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </Slide>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </QueueAnim>
                </ScrollOverPack>
                
                <ScrollOverPack
                    id="ivl-introduce"
                    className="ivl-page-base"
                    appear={false}
                >
                    <QueueAnim key="ivl2" leaveReverse type="bottom">
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol md='12' className="ivl-page2-col">
                                    <MDBCard className="ivl-introduce">
                                        <MDBCardBody>
                                            <Slide bottom>
                                                <MDBCardTitle className="ivl-page2-card-title">
                                                    {this.state.ivl_base.second_title}
                                                </MDBCardTitle>
                                            </Slide>
                                            <Slide bottom>
                                                <MDBCardText className="ivl-page2-card-text">
                                                    {this.state.ivl_base.second_content}
                                                </MDBCardText>
                                            </Slide>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>

                {/* 循环提取数据 */}
                {
                    this.state.ivl_introduce.map((item, index) => {
                        return (
                            (
                                <ScrollOverPack
                                    id={index}
                                    className="ivl-page2"
                                    appear={false}
                                    key={index}
                                >
                                    <QueueAnim key="ivl3" leaveReverse type="bottom">
                                        <MDBContainer key="ivl301" className="d-flex">
                                            <MDBRow>
                                            <MDBCol md='4' className="m-4 p-3 ivl-page2-col-card">
                                                    <Slide bottom>
                                                        <MDBCard className="ivl-page2-ai">
                                                            <MDBCardBody>
                                                                <MDBCardTitle>
                                                                    {item.title}
                                                                </MDBCardTitle>
                                                                <MDBTypography listUnStyled className='mb-0'>
                                                                    {
                                                                        item.content.map((i, x) => {
                                                                            return (
                                                                                (
                                                                                    <Slide bottom key={x}>
                                                                                        <li className='mb-1'>
                                                                                            <MDBIcon icon='angle-double-right' className='me-2 text-success' />
                                                                                            {i}
                                                                                        </li>
                                                                                    </Slide>
                                                                                )
                                                                            )
                                                                        })
                                                                    }
                                                                </MDBTypography>
                                                            </MDBCardBody>
                                                        </MDBCard>
                                                    </Slide>
                                                </MDBCol>
                                                <MDBCol md='7' className="m-4 p-3 ivl-page2-col-card">
                                                    <Slide bottom>
                                                        <MDBCard>
                                                            <video position='top' controls="controls" muted className="ivl-video-1" autoplay="autoplay" loop="loop" src={BASE_URL+item.mediaSrc}>
                                                            </video>
                                                        </MDBCard>
                                                    </Slide>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBContainer>
                                    </QueueAnim>

                                </ScrollOverPack>
                            )
                        )
                    })
                }
            </>
        )
    }
}