import React, { Component } from "react";
import {
    MDBRow,
    MDBCol,
    MDBContainer,
    MDBTypography
} from "mdb-react-ui-kit";
import {
    AppBar,
    Box,
    CssBaseline,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListItemAvatar,
    Avatar
} from "@mui/material";
import { Menu, ArrowDropDown } from "@mui/icons-material";
import newsBanner from '../../assets/banner3.png';
import '../news/news.css';
import { get_news_types, get_news_banner,get_news_title,get_news_content,get_news_top1 } from '../../axios/Api';
import { Slide, Fade } from 'react-reveal';
import { BASE_URL } from "../../axios/config";
const drawerWidth = 350;
export default class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            typeList: [],
            isExpand: 'panel2',
            news_banner:'',
            article_title:[],
            isFiexd: 0,
            news_doc:[]
        }
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    }
    handleDrawerToggle() {
        this.setState({
            mobileOpen: !this.state.mobileOpen
        });
    }
    componentDidMount() {
        this.handGetNewsTypes();
        this.getBanner();
        this.getNewsNew();
        window.addEventListener('scroll', this.bindHandleScroll);
    }
    bindHandleScroll = (e) => {
        const scrollTop = (e.srcElement ? e.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (e.srcElement ? e.srcElement.body.scrollTop : 0);
        this.setState({
            isFiexd: scrollTop
        })
    }
    getNowDate = () => {
        var date = new Date();
        var sign2 = ":";
        var year = date.getFullYear() // 年
        var month = date.getMonth() + 1; // 月
        var day = date.getDate(); // 日
        var hour = date.getHours(); // 时
        var minutes = date.getMinutes(); // 分
        var seconds = date.getSeconds() //秒
        // 给一位数的数据前面加 “0”
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (day >= 0 && day <= 9) {
            day = "0" + day;
        }
        if (hour >= 0 && hour <= 9) {
            hour = "0" + hour;
        }
        if (minutes >= 0 && minutes <= 9) {
            minutes = "0" + minutes;
        }
        if (seconds >= 0 && seconds <= 9) {
            seconds = "0" + seconds;
        }
        return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    }
    //默认加载最新一条数据
    getNewsNew = async () => {
        get_news_top1().then(res => {
            if (res.data.code === 200) {
                if (res.data.data !== null) {
                    this.setState({
                        news_doc: res.data.data
                    })
                } else {
                    this.setState({
                        news_doc:
                        {
                            title: '提示',
                            source: '系统提示',
                            author: '系统',
                            content: '请联系网站管理员添加数据',
                            publish_date: this.getNowDate()
                        }

                    })
                }
            } else {

            }
        }).catch(error => {

        });
    }
    //获取新闻类型
    handGetNewsTypes = async () => {
        get_news_types().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    typeList: res.data.data
                })
            }

        }).catch(error => {

        })
    }
    //根据新闻ID加载详细新闻
    handGetDetailByNewsID = (id,e) => {
        e.preventDefault();
        let data = {
            id:id
        }
        let cl = document.getElementsByClassName('news-left-menu');
        for(let i = 0; i<cl.length; i++){
            cl[i].classList.remove('activeted');
            if(Number(cl[i].getAttribute('id')) === id){
                cl[i].classList.add('activeted');
            }
        }
        get_news_content(data).then(res => {
            if(res.data.code === 200){
                this.setState({
                    news_doc:res.data.data,
                    mobileOpen:false
                })
                
            }
        }).catch(error => {

        });
    }
    //切换折叠
    handleChange = (panel,id) => (event, newExpanded) => {
        const data = {
            typeId: id
        }
        get_news_title(data).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    article_title: res.data.data,
                    isExpand: newExpanded ? panel : false
                })
            }
        }).catch(error => {

        })
    }
    //获取横幅
    getBanner = async () => {
        get_news_banner().then(res => {
            if(res.data.code === 200){
                this.setState({
                    news_banner:BASE_URL+(res.data.data.image_src.length>0?res.data.data.image_src.replace(/\\/, '/'):newsBanner)
                })
            }
        }).catch(error => {

        })
    }
    //渲染列表
    initArticleTitle() {
        if (this.state.article_title.length > 0) {
            return (
                <AccordionDetails sx={{ padding: '0px 16px 0px',maxHeight:'400px',overflowY:'auto' }}>
                    {
                        this.state.article_title.map((i, t) => {
                            return (
                                <ListItem key={i.id} alignItems="flex-start" id={i.id} 
                                className="news-left-menu"
                                sx={{ padding: '0px 16px 0px' }} 
                                onClick={this.handGetDetailByNewsID.bind(this,i.id)}
                                >
                                    <ListItemAvatar>
                                        <Avatar alt="Remy Sharp" src={BASE_URL + i.photo} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={i.title}
                                        secondary={
                                            <React.Fragment>
                                                {i.publish_date}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            )
                        })
                    }
                </AccordionDetails>
            )
        }
    }
    initNewsNav() {
        return (
            <List>
                {this.state.typeList.map((item, index) => (
                    <Slide left key={index}>
                        <Accordion expanded={this.state.isExpand === 'panel' + index} onChange={this.handleChange('panel' + index,item.id)}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDown />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>{item.article_type_name}</Typography>
                            </AccordionSummary>
                            {this.initArticleTitle()}
                        </Accordion>
                    </Slide>
                ))}
            </List>
        )
    }
    render() {
        return (
            <>
                <MDBRow style={{ margin: '0' }}>
                    <MDBCol md={12} style={{ padding: '0' }}>
                        <img alt='...' src={this.state.news_banner} className="service-banner-img" style={{ width: '100%', minHeight: '210px',objectFit:'cover',backgroundRepeat:'no-repeat',backgroundSize:'cover' }} />
                    </MDBCol>
                </MDBRow>
                <MDBContainer fluid className="news-container">
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <CssBaseline />
                        <Box
                            className="mt-2"
                            component="nav"
                            sx={{ width: { sm: drawerWidth, height: 'auto' }, flexShrink: { sm: 0 } }}
                            aria-label="新闻列表"
                            className={['remove-fiexd nav-style', this.state.isFiexd > 284 ? "fiexd-left" : null].join(' ')}
                        >
                            <Fade bottom>
                                <Toolbar className="news-types">
                                    <Typography variant="h6" noWrap component="div">
                                        新闻类型
                                    </Typography>
                                </Toolbar>
                            </Fade>
                            <Drawer
                                variant="temporary"
                                open={this.state.mobileOpen}
                                onClose={this.handleDrawerToggle}
                                className="news-drawer-list"
                                ModalProps={{
                                    keepMounted: true,
                                }}
                                sx={{
                                    display: { xs: 'block', sm: 'none' },
                                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                                }}
                            >
                                {this.initNewsNav()}
                            </Drawer>
                            <Drawer
                                variant="permanent"
                                className="news-left-bar"
                                sx={{
                                    display: { xs: 'none', sm: 'block' },
                                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                                }}
                                open
                            >
                                {this.initNewsNav()}
                            </Drawer>
                        </Box>
                        <div className={['news-main', this.state.isFiexd > 284 ? "left" : ""].join(' ')}>
                            <AppBar
                                className="news-header"
                                position="relative"
                                sx={{
                                    ml: { sm: `${drawerWidth}px` }, margin: '0 !important'
                                }}
                            >
                                <Toolbar style={{ background: '#fff', color: '#000' }}>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={this.handleDrawerToggle}
                                        sx={{ mr: 2, display: { sm: 'none' } }}
                                    >
                                        <Menu />
                                    </IconButton>
                                    <Typography variant="h6" noWrap component="div">
                                        点击这里选择新闻类型
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Box
                                component="main"
                                sx={{ flexGrow: 1, p: 2 }}
                            >
                                <Slide bottom>
                                    <figure className='text-center mb-1'>
                                        <MDBTypography blockquote>
                                            <p style={{ color: '#000',fontSize:'2rem',fontWeight:'600' }}>{this.state.news_doc.title}</p>
                                        </MDBTypography>
                                        <figcaption className='blockquote-footer mb-2'>
                                            来源:<cite style={{ marginRight: '15px' }}>{this.state.news_doc.source}</cite>
                                            作者:<cite style={{ marginRight: '15px' }}>{this.state.news_doc.author}</cite>
                                            发布时间:<cite>{this.state.news_doc.publish_date}</cite>
                                        </figcaption>
                                    </figure>
                                    <div className="body-content" dangerouslySetInnerHTML={{ __html: this.state.news_doc.content }}></div>
                                </Slide>
                            </Box>
                        </div>
                    </Box>
                </MDBContainer>
            </>
        );
    }
}