import React, { Component } from "react";
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBRow,
    MDBCol,
    MDBCardFooter,
    MDBIcon,
    MDBBtn,
    MDBBadge,
    MDBCardImage,
    MDBTypography
} from "mdb-react-ui-kit";
import './home.css';
import './bg.css';
import { Slide, Bounce, Fade } from 'react-reveal';
import BannerAnim, { Element } from 'rc-banner-anim';
import QueueAnim from "rc-queue-anim";
import ScrollAnim from 'rc-scroll-anim';
import TweenOne from "rc-tween-one";
import TextyAnim from "rc-texty";
import 'rc-banner-anim/assets/index.css';
import { BASE_URL } from "../../axios/config";
import { getHomeBanner, getProServer, getRealServer, get_dhcustom } from "../../axios/Api";
import { Alert, Snackbar } from "@mui/material";
import banner1h5 from '../../assets/banner1_h5.png';
import banner2h5 from '../../assets/banner2_h5.png';
import banner3h5 from '../../assets/banner3_h5.png';
import { isMobile } from "react-device-detect";
// import ReactPlayer from "react-player";
// import AIChat from "./AIChat";
const BgElement = Element.BgElement;
const ScrollOverPack = ScrollAnim.OverPack;
// const banner_url = 'https://www.qingpingtianyi.com/';

//随机动画
const anmia = ['alpha', 'left', 'right', 'top', 'bottom', 'scale', 'scaleBig', 'scaleX', 'scaleY'];
const random = (arr) => arr[Math.floor(Math.random() * arr.length)];
const randomAn = random(anmia);
const vertical = 'top';
const horizontal = 'center';
const h5_banner = [
    {
        id: 1,
        banner_path: banner1h5,
        banner_title: '',
        banner_content: '',
        banner_button: '',
        banner_link: ''
    },
    {
        id: 2,
        banner_path: banner2h5,
        banner_title: '',
        banner_content: '',
        banner_button: '',
        banner_link: ''
    },
    {
        id: 3,
        banner_path: banner3h5,
        banner_title: '',
        banner_content: '',
        banner_button: '',
        banner_link: ''
    }
]
export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banners: [],
            pro_ser: [],
            pro_ser_head: '',
            real_ser: [],
            real_ser_head: '',
            real_ser_subHead: [],
            show_alert: false,
            show_alert_tips: '',
            dh_custom: [],
            head_sub1: ''
        }
    };
    componentDidMount() {
        this.getBannerImg();
        this.getProSer();
        this.getRealSer();
        document.body.scrollIntoView();
        this.getDHCustom();
    };
    //获取轮播图
    getBannerImg = async () => {
        getHomeBanner().then(res => {
            if (res.data.code === 200) {
                //如果没有上传轮播图则使用默认
                this.setState({
                    // banners: isMobile === true ? h5_banner : res.data.data
                    banners: res.data.data
                })
            }
        }).catch(error => {
            if (error.code === "ECONNABORTED") {
                this.setState({
                    show_alert: true,
                    show_alert_tips: '远程服务器不可用，请检查！'
                })
            } else if (error.code === "ETIMEDOUT") {
                this.setState({
                    show_alert: true,
                    show_alert_tips: '请求超时，请稍后再试！'
                })
            } else {
                this.setState({
                    show_alert: true,
                    show_alert_tips: '网络异常，请检查网络连接是否正常！'
                })
            }
        });
    };
    //获取数字人定制数据
    getDHCustom = async () => {
        get_dhcustom().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    dh_custom: res.data.data,
                    head_sub1: res.data.data[0].name
                })
            }

        }).catch(error => {

        })
    };
    //获取产品与服务数据
    getProSer = async () => {
        getProServer().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    pro_ser: res.data.data,
                    pro_ser_head: res.data.data[0].name
                })
            }
        }).catch(error => {

        })
    };
    //获取真人定制服务
    getRealSer = async () => {
        getRealServer().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    real_ser: res.data.data,
                    real_ser_head: res.data.data[0].name,
                    real_ser_subHead: res.data.data[0].sub_name.split('#')
                })
            }
        }).catch(error => {

        })
    };
    //渲染数字人定制
    initDHCustom() {
        if (this.state.dh_custom.length > 0) {
            return (
                this.state.dh_custom.map((item, index) => {
                    return (
                        <MDBCol xl={6} className='mb-4' key={index}>
                            <Slide bottom>
                                <MDBCard className="home-card-hover">
                                    <MDBCardBody className="p-3">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex align-items-center'>
                                                <Slide bottom>
                                                    <img
                                                        src={BASE_URL + item.photo}
                                                        alt=''
                                                        style={{ width: '64px', height: '64px' }}
                                                        className='rounded-circle'
                                                    />
                                                </Slide>
                                                <Slide top cascade>
                                                    <div className='ms-3'>
                                                        <p className='fw-bold mb-1 server-title-text'>{item.title}
                                                        </p>
                                                        <p className='mb-0 server-body-text'>
                                                            {item.content}
                                                        </p>
                                                    </div>
                                                </Slide>
                                            </div>
                                        </div>
                                    </MDBCardBody>
                                    <MDBCardFooter border='0' className='p-2 d-flex justify-content-around'>
                                        <small><MDBIcon fas icon="street-view" className="me-2" />{item.desc}</small>
                                        <MDBBadge pill color='info' light>
                                            {item.label}
                                        </MDBBadge>
                                    </MDBCardFooter>
                                </MDBCard>
                            </Slide>
                        </MDBCol>
                    )
                })
            )
        }
    };
    //渲染副标题头
    initSubName() {
        if (this.state.real_ser_subHead.length > 0) {
            return (
                this.state.real_ser_subHead.map((item, index) => {
                    return (
                        (
                            <Slide top key={index}>
                                <div className="real-ser-title-content">
                                    <span style={{ color: '#bdbdbd' }}>{item}</span>
                                </div>
                            </Slide>
                        )
                    )
                })
            )
        }
    };
    render() {
        return (
            <div>
                {/* 背景 */}
                <div className="bg-container">
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                </div>
                <BannerAnim prefixCls="banner-user" >
                    {
                        this.state.banners.map((item, index) => {
                            return (

                                <Element
                                    prefixCls="banner-user-elem"
                                    key={index}
                                >
                                    <BgElement
                                        key="bg"
                                        className="bg"
                                        style={{
                                            // backgroundImage: 'url(' + (isMobile === true ? banner_url : BASE_URL) + item.banner_path.replace(/\\/, '/') + ')'
                                            backgroundImage: 'url(' + BASE_URL + item.banner_path.replace(/\\/, '/') + ')'
                                        }}
                                    />
                                    <QueueAnim className="banner-wrapper"
                                        key="QueueAnim"
                                        type={randomAn}
                                        delay={200}
                                    >
                                        <div key="title" className="banner-title">
                                            <h2>{item.banner_title}</h2>
                                        </div>
                                        {
                                            item.banner_content.split('#').map((i, t) => {
                                                return (
                                                    <div key={t} className="banner-content">
                                                        <span>{i}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                        <MDBBtn rounded outline key="button" tag='a' href={item.banner_link !== "" ? item.banner_link : ""}
                                            style={{ display: item.banner_button === "" ? "none" : "" }}
                                            target="_blank" className="banner-button">
                                            {item.banner_button !== "" ? item.banner_button : ""}
                                        </MDBBtn>
                                    </QueueAnim>
                                    <TweenOne
                                        animation={{
                                            y: '-=20',
                                            yoyo: true,
                                            repeat: -1,
                                            duration: 1000,
                                        }}
                                        className="banner-icon"
                                        key="icon"
                                    >
                                        <MDBIcon fas icon="angle-down" />
                                    </TweenOne>
                                </Element>
                            )

                        })
                    }

                </BannerAnim>
                {/* <ScrollOverPack
                    id="home"
                    className="home-sub2"
                    appear={false}
                >
                    <QueueAnim key="1" leaveReverse type="bottom">
                        <MDBContainer key="0" className="home-news mt-5 d-flex">
                            <TweenOne className="tween-one" key="0" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-4">
                                    <MDBCol md="12">
                                        <div className="title-wrapper">
                                            <h1 name="title" style={{color:'rgb(189, 189, 189)'}}>
                                                <span>云数字人</span>
                                            </h1>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow className="g-0">
                                <MDBCol md='7' className="mt-2">
                                    <MDBCard style={{background:'transparent'}} style={{boxShadow:'none'}}>
                                        <AIChat></AIChat>
                                    </MDBCard>
                                </MDBCol>
                                <MDBCol md='5' className="mt-2">
                                    <MDBCard className="video-card" style={{ background: 'transparent', boxShadow: 'none' }}>
                                        <ReactPlayer url='http://vodoutput.qingpingxiyue.com/live/guanwang.m3u8' playing />
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack> */}
                <ScrollOverPack
                    id="home"
                    className="home-sub2"
                    appear={false}
                >
                    <QueueAnim key="1" leaveReverse type="bottom">
                        <MDBContainer key="0" className="home-news mt-4 d-flex">
                            <TweenOne className="tween-one" key="0" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-4">
                                    <MDBCol md="12">
                                        <div className="title-wrapper">
                                            <h1 name="title" style={{ color: '#bdbdbd' }}>
                                                <span>{this.state.head_sub1}</span>
                                            </h1>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow>
                                {this.initDHCustom()}
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
                <ScrollOverPack
                    id="product"
                    className="product"
                    appear={false}
                >
                    <QueueAnim key="pro" leaveReverse type="bottom">
                        <MDBContainer key="pro0" className="home-news mt-4 d-flex">
                            <TweenOne className="tween-one" key="pro1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-4">
                                    <MDBCol md="12">
                                        <div className="title-wrapper">
                                            <h1 name="title" style={{ color: '#bdbdbd' }}>
                                                <span>{this.state.pro_ser_head}</span>
                                            </h1>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow>
                                {
                                    this.state.pro_ser.map((item, index) => {
                                        return (
                                            <MDBCol xl={4} className="home-div-product" key={index}>
                                                <Slide bottom>
                                                    <MDBCard className="pro-card-hover">
                                                        <MDBRow className='g-0'>
                                                            <MDBCol md='4'>
                                                                <Fade bottom>
                                                                    <div className="ratio ratio-1x1">
                                                                        <video controls="controls" muted autoplay="autoplay" loop="loop" src={BASE_URL + item.media_src}>
                                                                        </video>
                                                                    </div>
                                                                </Fade>
                                                            </MDBCol>
                                                            <MDBCol md='8'>
                                                                <MDBCardBody>
                                                                    <MDBCardTitle className="pro-ser-title">
                                                                        <Bounce bottom cascade>
                                                                            {item.title}
                                                                        </Bounce>
                                                                    </MDBCardTitle>
                                                                    <Fade bottom cascade>
                                                                        <TextyAnim className="product-text">
                                                                            {item.content}
                                                                        </TextyAnim>
                                                                    </Fade>
                                                                </MDBCardBody>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCard>
                                                </Slide>
                                            </MDBCol>
                                        )
                                    })
                                }
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
                {/* 真人定制服务 */}
                <ScrollOverPack
                    id="useflow"
                    className="real-ser"
                    appear={false}
                    style={{ backgroundColor: 'transparent', backgroundImage: 'none' }}
                >
                    <QueueAnim key="pro" leaveReverse type="bottom">
                        <MDBContainer key="pro0" className="home-news mt-4 d-flex">
                            <TweenOne className="tween-one" key="pro1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-4">
                                    <MDBCol md="12">
                                        <div className="title-wrapper mb-1">
                                            <Slide bottom>
                                                <h1 style={{ color: '#bdbdbd' }}>
                                                    <span>{this.state.real_ser_head}</span>
                                                </h1>
                                            </Slide>
                                            {this.initSubName()}
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
                                {
                                    this.state.real_ser.map((item, index) => {
                                        return (
                                            <Fade bottom key={index}>
                                                <MDBCol>
                                                    <MDBCard className='h-100 home-card-dz'>
                                                        <Slide bottom>
                                                            <MDBCardImage
                                                                src={BASE_URL + item.media_src}
                                                                position='top'
                                                            />
                                                        </Slide>
                                                        <MDBCardBody className="home-card-body">
                                                            <Slide right>
                                                                <MDBCardTitle>{item.title}</MDBCardTitle>
                                                            </Slide>
                                                            <MDBTypography listUnStyled className='mb-0 home-card-dz-text'>
                                                                <Fade top>
                                                                    <li className='mb-1'>
                                                                        <MDBIcon fas icon="smile" className='me-2 text-info' />{item.content1}
                                                                    </li>
                                                                </Fade>
                                                                <Fade bottom>
                                                                    <li className='mb-1'>
                                                                        <MDBIcon fas icon="smile" className='me-2 text-info' />{item.content2}
                                                                    </li>
                                                                </Fade>
                                                            </MDBTypography>
                                                            {/* </MDBCardText> */}
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </MDBCol>
                                            </Fade>
                                        )
                                    })
                                }
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={this.state.show_alert}
                    message={this.state.show_alert_tips}
                    key={vertical + horizontal}
                >
                    <Alert severity="error" sx={{ width: '100%' }}>
                        {this.state.show_alert_tips}
                    </Alert>
                </Snackbar>
            </div>
        )
    }

}