import React, { Component } from "react";
import {
    MDBContainer,
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardTitle,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBTypography
} from "mdb-react-ui-kit";
import { Slide, Fade } from 'react-reveal';
import QueueAnim from 'rc-queue-anim';
import ScrollAnim from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import 'rc-texty/assets/index.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { 
    get_livevh_banner,
    get_livevh_base,
    get_livevh_sub1,
    get_livevh_sub2,
    get_livevh_sub3,
    get_livevh_base_sub1,
    get_livevh_base_sub2
 } from "../../axios/Api";
import { BASE_URL } from "../../axios/config";
import '../livevh/livevh.css';
import { Autoplay, EffectCoverflow } from 'swiper/modules';
import default_banner from '../../assets/banner2.png';
const ScrollOverPack = ScrollAnim.OverPack;

export default class Livevh extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banner_src: '',
            show_company_des: true,
            base_data:[],
            base_sub1:[],
            base_sub2:[],
            sub1:[],
            sub1_head:'',
            sub2:[],
            sub2_head:'',
            sub3:[],
            sub3_head:''
        }
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        document.body.scrollIntoView();
        this.getLiveBanner();
        this.getBase();
        this.getBaseSub1();
        this.getBaseSub2();
        this.getSub1();
        this.getSub2();
        this.getSub3();
    }
    getLiveBanner = async () => {
        get_livevh_banner().then(res => {
            if (res.data.code === 200) {
                //如果没有上传轮播图则使用默认
                this.setState({
                    banner_src: BASE_URL + (res.data.data.image_src.length > 0 ? res.data.data.image_src : default_banner)
                })
            }
        }).catch(error => {

        })
    }
    //基本信息
    getBase = async() => {
        get_livevh_base().then(res => {
            if(res.data.code === 200){
                this.setState({
                    base_data:res.data.data
                })
            }
        }).catch(error => {

        })
    }
    //基本信息下第一段
    getBaseSub1 = async() => {
        get_livevh_base_sub1().then(res => {
            if(res.data.code === 200){
                this.setState({
                    base_sub1:res.data.data
                })
            }
        }).catch(error => {

        })
    }
    //渲染
    initBaseSub1(){
        if(this.state.base_sub1.length > 0){
            return(
                this.state.base_sub1.map((item,index) => {
                    return(
                        (
                            <div className="p-3" key={index}>
                                    <Fade bottom>
                                        <MDBCard className='h-100 home-card-dz'>
                                            <Slide bottom>
                                                <MDBCardImage
                                                    src={BASE_URL+item.photo}
                                                    alt=''
                                                    position='top'
                                                />
                                            </Slide>
                                        </MDBCard>
                                    </Fade>
                                </div>
                        )
                    )
                })
            )
        }
    }
    //基本信息第二段
    getBaseSub2 = async() => {
        get_livevh_base_sub2().then(res => {
            if(res.data.code === 200){
                this.setState({
                    base_sub2:res.data.data
                })
            }
        }).catch(error => {

        })
    }
    //渲染
    initBaseSub2(){
        if(this.state.base_sub2.length > 0){
            return(
                this.state.base_sub2.map((item,index) => {
                    return(
                        (
                            <MDBCol xl={3} className='mb-2' key={index}>
                                                <Slide bottom>
                                                    <MDBCard>
                                                        <MDBCardBody className="p-3">
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <div className='d-flex align-items-center'>
                                                                    <Fade bottom>
                                                                        <img
                                                                            src={BASE_URL+item.photo}
                                                                            alt=''
                                                                            style={{ width: '45px', height: '45px' }}
                                                                        />
                                                                    </Fade>

                                                                    <Slide top cascade>
                                                                        <div className='ms-3'>
                                                                            <p className='text-muted mb-0 home-news-body-text'>{item.content}</p>
                                                                        </div>
                                                                    </Slide>
                                                                </div>
                                                            </div>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </Slide>
                                            </MDBCol>
                        )
                    )
                })
            )
        }
    }
    //第一段
    getSub1 = async() => {
        get_livevh_sub1().then(res => {
            if(res.data.code === 200){
                this.setState({
                    sub1:res.data.data,
                    sub1_head:res.data.data[0].name
                })
            }
        }).catch(error => {

        })
    }
    //渲染第一段
    initSub1(){
        if(this.state.sub1.length > 0){
            return(
                this.state.sub1.map((item,index) => {
                    return(
                        (
                            <Fade bottom key={index}>
                                    <MDBCol className="p-2">
                                        <MDBCard className='h-100 home-card-dz'>
                                            <Slide top>
                                                <MDBCardImage
                                                    src={BASE_URL+item.photo}
                                                    alt=''
                                                    position='top'
                                                    className="product-good-img"
                                                />
                                            </Slide>
                                            <MDBCardBody className="home-card-body">
                                                <Slide top cascade>
                                                    <MDBCardTitle className="product-good-title">{item.title}</MDBCardTitle>
                                                </Slide>
                                                <MDBTypography listUnStyled className='mb-0 home-card-dz-text'>
                                                    <Slide bottom cascade>
                                                        <li className='mb-1 product-good-text'>
                                                            {item.content}
                                                        </li>
                                                    </Slide >
                                                </MDBTypography>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </Fade>
                        )
                    )
                })
            )
        }
    }
    //第二段
    getSub2 = async() => {
        get_livevh_sub2().then(res => {
            if(res.data.code === 200){
                this.setState({
                    sub2:res.data.data,
                    sub2_head:res.data.data[0].name
                })
            }
        }).catch(error => {

        })
    }
    //渲染第二段
    initSub2(){
        if(this.state.sub2.length > 0){
            return(
                this.state.sub2.map((item,index) => {
                    return(
                        (
                                <SwiperSlide key={index}>
                                <Fade bottom>
                                    <div className="ratio ratio-1x1">
                                        <video autoplay="autoplay" loop="loop" muted src={BASE_URL+item.photo}>
                                        </video>
                                    </div>
                            </Fade>
                                </SwiperSlide>
                        )
                    )
                })
            )
        }
    }
    //第三段
    getSub3 = async() => {
        get_livevh_sub3().then(res => {
            if(res.data.code === 200){
                this.setState({
                    sub3:res.data.data,
                    sub3_head:res.data.data[0].name
                })
            }
        }).catch(error => {

        })
    }
    //渲染第三段
    initSub3(){
        if(this.state.sub3.length > 0){
            return(
                this.state.sub3.map((item,index) => {
                    return(
                        (
                            // <MDBCol size="4" xl={2} className='mb-4' key={index}>
                            //         <Slide bottom>
                            //             <MDBCard className="use-platform-card">
                            //                 <MDBCardBody className="p-3">
                            //                     <div className='d-flex justify-content-between align-items-center use-platform-div'>
                            //                         <div className='d-flex align-items-center'>
                            //                             <Bounce top>
                            //                                 <img
                            //                                     src={BASE_URL+item.photo}
                            //                                     alt=''
                            //                                     className='use-platform-img'
                            //                                 />
                            //                             </Bounce>
                            //                         </div>
                            //                     </div>
                            //                 </MDBCardBody>
                            //             </MDBCard>
                            //         </Slide>
                            //     </MDBCol>
                            <MDBCol size="3" xl={1} className='mb-4' key={index}>
                                    <Slide bottom>
                                    <img
                                                                src={BASE_URL+item.photo}
                                                                alt=''
                                                                className='use-platform-img'
                                                            />
                                        {/* <MDBCard className="use-platform-card">
                                            <MDBCardBody className="p-3">
                                                <div className='d-flex justify-content-between align-items-center use-platform-div'>
                                                    <div className='d-flex align-items-center'>
                                                        <Bounce top>
                                                            <img
                                                                src={BASE_URL+item.photo}
                                                                alt=''
                                                                className='use-platform-img'
                                                            />
                                                        </Bounce>
                                                    </div>
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard> */}
                                    </Slide>
                                </MDBCol>
                        )
                    )
                })
            )
        }
    }
    render() {
        return (
            <div>
                <MDBRow style={{ margin: '0' }}>
                    <MDBCol md={12} style={{ padding: '0' }}>
                        <img alt='...' src={this.state.banner_src} className="livevh-banner-img" style={{ width: '100%', minHeight: '210px',objectFit:'cover' ,backgroundRepeat:'no-repeat',backgroundSize:'cover'}} />
                    </MDBCol>
                </MDBRow>
                <ScrollOverPack
                    id="whatai"
                    className="what-ai"
                    appear={false}
                >
                    <QueueAnim key="pro" leaveReverse type="bottom">
                        <MDBContainer key="pro0" className="livevh-container mt-4 d-flex">
                            <TweenOne className="tween-one" key="pro1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-4">
                                    <MDBCol md="12">
                                        <Fade bottom>
                                            <div className="title-wrapper mb-4">
                                                <h1>
                                                    {this.state.base_data.title}
                                                </h1>
                                            </div>
                                        </Fade>
                                        {/* <MDBTypography className='lead mb-0'> */}
                                        <Fade bottom cascade>
                                            {/* <TextyAnim mode="smooth" type="mask-bottom"> */}
                                                {this.state.base_data.content}
                                            {/* </TextyAnim> */}
                                        </Fade>
                                        {/* </MDBTypography> */}
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <div className="d-flex flex-row show-img-center mt-1">
                                {this.initBaseSub1()}
                            </div>
                            <QueueAnim key="1" leaveReverse type="bottom">
                                    <MDBContainer key="0" className="mt-3 d-flex mb-4">
                                        <MDBRow>
                                            {this.initBaseSub2()}
                                        </MDBRow>
                                    </MDBContainer>
                                </QueueAnim>
                            {/* <ScrollOverPack
                                id="base-ins"
                                className="base-ins"
                                appear={false}
                            >
                                <QueueAnim key="1" leaveReverse type="bottom">
                                    <MDBContainer key="0" className="mt-3 d-flex mb-4">
                                        <MDBRow>
                                            {this.initBaseSub2()}
                                        </MDBRow>
                                    </MDBContainer>
                                </QueueAnim>
                            </ScrollOverPack> */}
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
                {/* 产品优势 */}
                <ScrollOverPack
                    id="product-good"
                    className="real-ser"
                    appear={false}
                >
                    <QueueAnim key="pro" leaveReverse type="bottom">
                        <MDBContainer key="pro0" className="home-news mt-4 d-flex">
                            <TweenOne className="tween-one" key="pro1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-2">
                                    <MDBCol md="12">
                                        <Fade bottom>
                                            <div className="product-good-title-wrapper mb-4">
                                                <h1>
                                                    {this.state.sub1_head !==""?this.state.sub1_head:"无数据"}
                                                </h1>
                                            </div>
                                        </Fade>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow className='row-cols-1 row-cols-md-4 g-4'>
                                {this.initSub1()}
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
                {/* 直播案例 */}
                <ScrollOverPack
                    id="live-demo"
                    className="real-ser"
                    appear={false}
                >
                    <QueueAnim key="pro" leaveReverse type="bottom">
                        <MDBContainer key="pro0" className="home-news mt-4 d-flex">
                            <TweenOne className="tween-one" key="pro1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-4">
                                    <MDBCol md="12">
                                        <Fade bottom>
                                            <div className="product-good-title-wrapper mb-4">
                                                <h1>
                                                    {this.state.sub2_head !== "" ?this.state.sub2_head :"无数据"}
                                                </h1>
                                            </div>
                                        </Fade>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <Swiper
                                effect={'coverflow'}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={'auto'}
                                coverflowEffect={{
                                    rotate: 50,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 1,
                                    slideShadows: true,
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, EffectCoverflow]}
                                className="swiper"
                            >
                                {this.initSub2()}
                            </Swiper>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
                {/* 适用平台 */}
                <ScrollOverPack
                    id="use-platform"
                    className="use-platform"
                    appear={false}
                    playScale={0.3}
                >
                    <QueueAnim key="platform0" leaveReverse type="bottom">
                        <MDBContainer key="0" className="home-news mt-4 d-flex">
                            <TweenOne className="tween-one" key="0" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-4">
                                    <MDBCol md="12">
                                    <Fade bottom>
                                        <div className="title-wrapper">
                                            <h1 name="title">
                                                <span>{this.state.sub3_head !== "" ? this.state.sub3_head : "无数据"}</span>
                                            </h1>
                                        </div>
                                    </Fade>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow style={{justifyContent:"space-between"}}>
                                {this.initSub3()}
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size="12" xl={12} className="mb-2">
                                    <Fade bottom>
                                        <MDBTypography variant='h4' style={{ textAlign: 'center' }}>
                                            {this.state.base_data.foot_desc !== "" ? this.state.base_data.foot_desc : ""}
                                        </MDBTypography>
                                    </Fade>
                                </MDBCol>
                                <MDBCol size="12" xl={12} className="mb-2">
                                    <Fade bottom>
                                        <MDBTypography variant='h3' style={{ textAlign: 'center' }}>
                                            {this.state.base_data.foot_b !== "" ? this.state.base_data.foot_b : ""}
                                        </MDBTypography>
                                    </Fade>
                                </MDBCol>
                                <MDBCol size="12" xl={12} className="mb-2 use-now" style={{ textAlign: 'center' }}>
                                    <Fade bottom>
                                        <MDBBtn rounded tag='a' href={this.state.base_data.button_link !== "" ? this.state.base_data.button_link : ""} target="_blank" size='lg'>
                                            {this.state.base_data.button_name !== "" ? this.state.base_data.button_name : ""}
                                        </MDBBtn>
                                    </Fade>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
            </div>
        )
    }
}